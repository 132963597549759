<template>
  <div class="view-logout">
    <b-card title="Logout" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="icon/lock_48x48.png" alt="Logout Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <p>
              The logout process will securely clean up any information in the
              browser as well as permanently close the associated session on the
              server, preventing any cached information from being usable. As a
              rule, this page should not be displayed for long. Upon completion
              the page will reload.
            </p>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-logout",
  components: {
    BCard
  },
  data() {
    return {
      session_internal: null,
      error: "",
      disabled: false
    };
  },
  props: {
    session: {
      default: null,
      type: Object
    }
  },
  methods: {
    doLogout() {
      if (
        this.session_internal.loginId != null &&
        this.session_internal.loginId > 0
      ) {
        this.disabled = true;
        API.delete(
          API.url.authentication.login,
          this,
          "",
          function(obj, data) {
            obj.session_internal = data;
            obj.$emit("onComplete", obj.session_internal);
            return;
          },
          function(obj, error) {
            obj.showErrorMessage("Unable to logout: " + error);
            SVGForeignObjectElement.disabled = false;
          }
        );
      }
    },
    showErrorMessage(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-danger"
      });
    }
  },
  mounted() {
    this.session_internal = this.session;
    this.doLogout();
  }
};
</script>

<style scoped></style>
