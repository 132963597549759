import axios from "axios";

export default {
  name: "api",

  url: {
    base: {
      tag: "/api/tag/",
      asset: "/api/asset/",
      assetType: "/api/assetType/",
    },
    authentication: {
      login: "/api/login/",
      picklist: "/api/authentication/pickList/",
      registration: "/api/authentication/registration/",
      session: "/api/authentication/session/",
      user: "/api/authentication/user/"  
    },    
    news: "/api/news/",
    product: {
      product: "/api/product/",
      tag: "/api/product/productTag/"
    }    
  },
  list: function(url, obj, onComplete, onError, page = 0, count = 100) {
    axios
      .get(url + "?page=" + page + "&count=" + count)
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  },

  getUrl: function(url, obj, onComplete, onError) {
    axios
      .get(url)
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  },

  get: function(url, obj, id, onComplete, onError) {
    axios
      .get(url + id)
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  },

  post: function(url, obj, model, onComplete, onError) {
    axios
      .post(url, model)
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        if (response.status == 400) {
          onError(obj, response.response);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  },

  put: function(url, obj, id, model, onComplete, onError) {
    axios
      .put(url + id, model)
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        if (response.status == 400) {
          onError(obj, response);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  },

  delete: function(url, obj, id, onComplete, onError) {
    axios
      .delete(url + id)
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  },

  model: function(url, obj, onComplete, onError) {
    axios
      .get(url + "model")
      .then(response => {
        if (response.status == 200) {
          onComplete(obj, response.data);
          return;
        }
        onError(obj, response.statusText);
      })
      .catch(error => onError(obj, error));
  }
};
