<template>
  <div id="component-login-dialog">
    <b-card title="Login" :centered="true">
      <form action="" @submit="onSubmit">
        <div class="media left">
          <div class="media-left is-hidden-touch">
            <figure class="image is-48x48">
              <img src="icon/ulock_48x48.png" alt="Login Icon" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <p>
                By logging into this system you are agreeing to the terms and
                conditions of the user agreement. Unauthorized use of the system
                will be prosecuted to the fullest extent of the law.
              </p>
              <b-field label="User" label-position="on-border">
                <b-input
                  ref="login-userName"
                  v-model="login.userName"
                  placeholder="Enter your user name"
                  required
                  autofocus
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <b-field label="Password" label-position="on-border">
                <b-input
                  v-model="login.password"
                  type="password"
                  placeholder="Enter your password"
                  required
                  :disabled="disabled"
                ></b-input>
              </b-field>
            </div>
            <div class="level is-mobile">
              <div class="level-left">
                <small class="level-item">
                  <a href="/forgotten">
                    [ Forgotten password? ]
                  </a>
                </small>
                <small class="level-item has-text-danger" v-if="error!=''">
                  {{ error }}
                </small>
              </div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link mr-3"
                    :disabled="disabled"
                  >
                    Login
                  </button>
                  <button
                    type="cancel"
                    class="button is-link is-light"
                    @click="onCancel"
                    :disabled="disabled"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-login",
  components: {
    BCard,
  },
  data() {
    return {
      session_internal: null,
      login: { userName: "", password: "", uid: "" },
      error: "",
      disabled: false,
    };
  },
  props: {
    session: {
      default: null,
      type: Object,
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (this.login.userName != "" && this.login.password != "") {
        this.disabled = true;
        API.put(
          API.url.authentication.login,
          this,
          "",
          this.login,
          function(obj, data) {
            if (data.login == null) {
              obj.disabled = false;
              obj.showErrorMessage("Unable to login this user.");
              obj.$refs["login-userName"].focus();
              return;
            }
            obj.session_internal = data;
            obj.$emit("onComplete", obj.session_internal);
            obj.login = { userName: "", password: "", uid: "" };
            obj.disabled = false;
          },
          function(obj, err) {
            obj.disabled = false;
            // depending upon the return, there are several actions needed.

            if (err.response.data != null) {
              switch (err.response.data.code) {
                case 400:
                case 401:
                case 402:
                case 403:
                case 405:
                case 406:
                case 407:
                case 410:
                  obj.error = err.response.data.message;
                  return;
                default:
                  obj.showErrorMessage("Server returned an error: " + err.data.message);
                  return;
              }
            }
            obj.showErrorMessage("Server returned an error: " + err.message);
          }
        );
      } else {
        this.error = "A username and password must be entered";
        this.active = true;
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onForgotten() {
      this.router.push("/forgotten");
      this.$emit("onCancel");
    },
    showErrorMessage(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-danger",
      });
    },
  },
  mounted() {
    this.session_internal = this.session;
    this.login = { userName: "", password: "", uid: "" };
    this.disabled = false;
  },
};
</script>
