<template>
  <div id="app">
    <div id="layout">
      <div id="header">
        <div id="title">{{ this.pageName }}</div>
        <div class="mybreadcrumb">
          <span v-for="item in breadcrumbs" :key="breadcrumbs.indexOf(item)">
            <router-link :to="item.url">{{ item.name }}</router-link> /
          </span>
        </div>
        <img id="logo" alt="Druware Software Designs" />
      </div>
      <div id="navigation">
        <div id="nav">
          <router-link class="navitem" to="/">
            <img src="/icon/home_48x48.png" alt="Home" />
            <label>Home</label>
          </router-link>
          <router-link class="navitem" to="/products">
            <img src="/icon/cart_48x48.png" alt="Products" />
            <label>Products</label>
          </router-link>
          <router-link class="navitem" to="/support">
            <img src="/icon/help_48x48.png" alt="√" />
            <label>Support</label>
          </router-link>
          <router-link class="navitem" to="/about">
            <img src="/icon/sinfo_48x48.png" alt="About" />
            <label>About</label>
          </router-link>
          <hr />
          <div v-if="!isLoggedIn">
            <a class="navitem" @click="onLogin">
              <img src="/icon/ulock_48x48.png" alt="Login" />
              <label>Login</label>
            </a>
          </div>
          <div v-if="isLoggedIn">
            <router-link class="navitem" to="/admin" v-if="showAdmin">
              <img src="/icon/confg_48x48.png" alt="Administration" />
              <label>Administration</label>
            </router-link>
            <router-link
              class="navitem"
              to="/dashboard"
              v-if="isDashboardEnabled"
            >
              <img src="/icon/confg_48x48.png" alt="Dashboard" />
              <label>Dashboard</label>
            </router-link>
            <router-link class="navitem" to="/profile">
              <img src="/icon/user_48x48.png" alt="User Profile" />
              <label>Profile</label>
            </router-link>
            <a class="navitem" @click="onLogout">
              <img src="/icon/lock_48x48.png" alt="Logout" />
              <label>Logout</label>
            </a>
          </div>
          <div class="has-text-light">
            {{ /* expiresIn */ }}
          </div>
          <br />
        </div>
      </div>
      <div id="content">
        <transition name="fade">
          <router-view
            :session.sync="session"
            @onSessionChange="onSessionChange"
          />
        </transition>
      </div>
    </div>
    <b-modal
      v-model="isLoginDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      width="80%"
      scroll="keep"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <login-form
          @onComplete="onLoginComplete"
          @onCancel="onLoginCancel"
        ></login-form>
      </template>
    </b-modal>

    <b-modal
      v-model="isLogoutDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <logout-form
          :session="session"
          @onComplete="onLogoutComplete"
        ></logout-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import LoginForm from "@/Druware.Web.Shared/dialogs/Login";
import LogoutForm from "@/Druware.Web.Shared/dialogs/Logout";

export default {
  components: {
    LoginForm,
    LogoutForm,
  },
  data: function() {
    return {
      session: {},
      isLoginDialogActive: false,
      isLogoutDialogActive: false,
      isProfileDialogActive: false,
      pageName: "",
      crumb: {
        name: "",
        url: "",
      },
      breadcrumbs: [],
      timer: "",
      expiresIn: 0,
      isDashboardEnabled: false,
    };
  },
  computed: {
    showAdmin: function() {
      if (this.session == null) return false;
      if (this.session.login == null) return false;
      if (this.session.login.access == null) return false;
      return this.session.login.access.includes(
        "6d736fa5-cb3d-48d8-a6c9-5affacef8b2f"
      );
    },
    isLoggedIn: function() {
      if (this.session == null) return false;
      return this.session.login != null;
    },
  },
  methods: {
    autoLogout() {
      clearInterval(this.timer);
      this.timer = "";
      if (this.expiresIn > 0) {
        this.expiresIn--;
        this.timer = setInterval(this.autoLogout, 1000);
        return;
      }
      this.showMessage("Session Timed out, automatically logging out");
      API.delete(
        API.url.authentication.login,
        this,
        "",
        function(obj, data) {
          obj.session = data;
          return;
        },
        function(obj, error) {
          obj.showErrorMessage("Unable to logout: " + error.message);
          obj.disabled = false;
        }
      );
    },
    createBreadcrumbsFromPath(path) {
      this.breadcrumbs = [];
      var _u = "";
      var _path = path.split("/");
      for (var i = 0; i < _path.length; i++) {
        _u += _path[i] + "/";
        var c = new Object();
        c.name = _path[i];
        c.url = _u;
        if (c.name == "" && this.breadcrumbs.length > 0) break;
        this.breadcrumbs.push(c);
      }
    },
    onLogin() {
      this.isLoginDialogActive = true;
    },
    onLoginComplete(session) {
      this.session = session;
      this.isLoginDialogActive = false;
    },
    onLoginCancel() {
      this.isLoginDialogActive = false;
    },
    onLogout() {
      this.isLogoutDialogActive = true;
    },
    onLogoutComplete(session) {
      this.session = session;
      this.isLogoutDialogActive = false;
    },
    onSessionChange(session) {
      this.session = session;
    },
    showMessage(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-danger",
      });
    },
    getSession() {
      API.get(
        API.url.authentication.session,
        this,
        "",
        function(obj, data) {
          obj.session = data;
        },
        function(obj, err) {
          obj.showMessage(err.message);
        }
      );
    },
  },
  watch: {
    $route(to, from) {
      if (to == from) return;
      this.createBreadcrumbsFromPath(to.path);
      this.pageName = to.name;
      window.document.title =
        "Druware - " + (this.pageName ? this.pageName : "");
      this.getSession();
    },
    session(to, from) {
      var p = this.$router.currentRoute.path;
      clearInterval(this.timer);
      this.timer = "";
      if (to == from) return;
      if (to.login == from.login) return;
      if (
        (from.loginId != null || from.loginId > 0) &&
        (to.loginId == null || to.loginId < 1)
      ) {
        // if the route contains admin/apps/profile then return home.
        if (p != "/" && p != "/home") {
          this.$router.push("/");
        }
        return;
      }
      // use the expiration to set an auto logout
      var now = new Date();
      var expires = new Date(this.session.expires);
      var span = Math.round((expires - now) / 1000);
      this.expiresIn = span;
      if (to.loginId != null && to.loginId > 0)
        this.timer = setInterval(this.autoLogout, 1000);
    },
  },
  mounted() {
    if (this.session.sessionId == null) {
      this.getSession();
    }
    this.expiresIn = 3 * 60;
    this.pageName = this.$router.currentRoute.name;
    if (this.$pageName != null) this.pageName = this.$pageName;
    this.createBreadcrumbsFromPath(this.$router.currentRoute.path);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = "";
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1px) {
  #app {
    height: 100%;
    width: 100%;
    #layout {
      position: absolute;
      background: #cccccc;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      #header {
        position: absolute;
        background: rgba(89, 91, 122, 0.9);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 64px;
        z-index: 2;
        #title {
          font-size: 1.7em;
          color: #ffffff;
        }
        .mybreadcrumb {
          position: absolute;
          top: 42px;
          left: 12px;
          width: auto;
          color: #ebebeb;
          font-size: 12pt;
          clear: none;
          a {
            color: #ebebeb;
            text-decoration: none;
          }
        }
        #logo {
          content: url("/img/druware_default.png");
          position: absolute;
          left: calc(100% - 64px);
          top: 0px;
          width: 64px;
          max-width: 64px;
          height: 64px;
          float: right;
          clear: none;
        }
      }
      #navigation {
        position: absolute;
        background: rgba(0, 0, 0, 0.75);
        top: 64px;
        left: calc(100% - 64px);
        width: 64px;
        height: calc(100% - 64px);
        padding: 2px;
        overflow: hidden;
        #nav {
          padding: 0px;
          position: relative;
          top: 8px;
          left: 8px;
          right: 6px;
          overflow: hidden;
          .navitem {
            margin-bottom: 4px;
            img {
              width: 48px;
              height: 48px;
            }
            label {
              display: none;
            }
          }
          a {
            font-weight: bold;
            text-decoration: none;
            color: #c0c0c0;
            display: block;
            clear: all;
          }
          a.router-link-exact-active {
            background-color: #c0c0c0;
          }
        }
        hr {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
      #content {
        position: absolute;
        top: 64px;
        left: 0px;
        padding: 8px;
        width: calc(100% - 64px);
        height: calc(100% - 64px);
        overflow-y: auto;
        background-color: #fcfcfc;
      }
    }
  }
}

@media screen and (min-width: 767px) {
  #app {
    #layout {
      #header {
        #logo {
          content: url("/img/dsd-new-logo-small.png");
          left: calc(100% - 160px);
          width: 160px;
          max-width: 160px;
        }
      }
      #navigation {
        left: calc(100% - 160px);
        width: 160px;
        #nav {
          .navitem {
            img {
              display: none;
            }
            label {
              display: inline-block;
            }
          }
          a.router-link-exact-active {
            background-color: transparent;
            color: #ffffff;
          }
        }
      }
      #content {
        width: calc(100% - 160px);
      }
    }
  }
}

@media screen and (min-width: 1023px) {
  #app {
    #layout {
      #header {
        top: 16px;
        left: 0px;
        #logo {
          content: url("/img/dsd-new-logo-narrow.png");
          left: calc(100% - 200px);
          width: 200px;
          max-width: 200px;
        }
      }
      #navigation {
        padding-top: 80px;
        top: 0px;
        left: calc(100% - 200px);
        width: 200px;
        height: 100%;
        #nav {
          .navitem {
            vertical-align: middle;
            clear: all;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              display: inline-block;
            }
            label {
              display: inline-block;
            }
          }
          a.router-link-exact-active {
            background-color: transparent;
            color: #ffffff;
          }
        }
      }
      #content {
        width: calc(100% - 200px);
        min-height: calc(100%);
        top: 0px;
        padding-top: 88px;
        overflow-x: hidden;
      }
    }
  }
}
</style>
