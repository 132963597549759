<template>
  <div class="view-home">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="hero is-warning p-3 mb-3" v-if="showHeader">
      System Alerts
    </div>
    <div v-for="item in list" :key="list.indexOf(item)">
      <article-summary :session="session" :article="item"></article-summary>
    </div>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import ArticleSummary from "@/Druware.Web.Shared/components/ArticleSummary";

export default {
  name: "Home",
  components: {
    ArticleSummary
  },
  data: function() {
    return {
      session: {},
      list: [],
      isLoading: false,
      showHeader: false,
      currentPage: 0
    };
  },
  methods: {
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    getList() {
      this.isLoading = true;
      API.list(
        API.url.news,
        this,
        function(obj, data) {
          obj.list = data.list;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showMessage(err.message);
          obj.isLoading = false;
        },
        this.currentPage,
        10
      );
    }
  },
  mounted() {
    this.isLoading = true;
    this.session = this.$attrs.session;
    this.getList();
  }
};
</script>
