<template>
  <div class="card">
    <!--div class="card-image"><img src="" alt="Header Image"/></div-->
    <header v-if="icon != null || title != null" class="card-header">
      <div v-if="icon != null" class="card-header-icon">
        <img :src="icon" :alt="iconText" />
      </div>
      <div v-if="title != null" :class="headerTitleClass">{{ title }}</div>
    </header>

    <div class="card-content">
      <slot name="default"></slot>
    </div>

    <footer class="card-footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: "BCard",
  provide() {
    return {
      BCard: this
    };
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    iconText: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: null
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerTitleClass() {
      return "card-header-title" + (this.centered ? " is-centered" : "");
    }
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
